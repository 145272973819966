<div class="content content-two-column-aside-left-fixed">
  <div class="column column-primary">

    <div class="component primary-component breadcrumbs">
      <!-- <p-breadcrumb [model]="items"></p-breadcrumb> -->
    </div>

    <div class="component pagetitle-component">
      <h1>Hi {{firstName}}!</h1>
    </div>

    <app-to-do [toDoList]="toDoList"></app-to-do>
    <app-to-do [oePeriodList]="oePeriodList"></app-to-do>

    <section class="component primary-component">
      <div class="component-heading">
        <h2>Plan Stats</h2>
      </div>
      <article class="component-content">
        <p-card>
          <div class="circle-graph-dropdowns">

            <select [(ngModel)]="groupName" class="graph" (change)="groupChanged()">
              <option value="Health Insurance">Health</option>
              <option value="Dental Insurance">Dental</option>
              <option value="Vision Insurance">Vision</option>
              <option value="Cafeteria Plan - Dependent Care Spending Account">FSA</option>
              <option value="Cafeteria Plan - Health Care Spending Account">HSA</option>
            </select>
            <select [(ngModel)]="status" class="graph" (change)="groupChanged()">
              <option value="Current">Current</option>
              <option value="Enroll">Enroll</option>
            </select>
            <select [(ngModel)]="benefitPlanID" class="graph" (change)="planChanged()">
              <option value="0">All Plans</option>
              <option *ngFor="let plan of plans" [value]="plan.benefitPlanID">{{plan.displayName}}</option>
            </select>
            <span *ngIf="optionGroupsVisible">
              <select [(ngModel)]="optGroupNum" (change)="optGroupChanged()" class="graph">
                <option value="-1">All Option Groups</option>
                <option *ngFor="let optgroup of optgroups" [value]="optgroup.optGroupNum">{{optgroup.displayName}}</option>
              </select>
            </span>

          </div>
          <div class="circle-graph-info">

            <div [hidden]="!multiCircleVisible">
              <div id="circg_2" class="circ_graph"></div>
            </div>
          
            <ul>
              <li>Covered Employees: {{eeCovered}}</li>
              <li>Covered Spouses: {{spouseCovered}}</li>
              <li>Covered Children: {{childCovered}}</li>
            </ul>

          </div>
        </p-card>
      </article>
    </section>

    <app-thought-leadership [thoughtLeadershipItems]="thoughtLeadershipData"></app-thought-leadership>

  </div>
  <!-- SIDEBAR NAVIGATION -->
  <aside class="column column-aside">
    <!-- ***** COLUMN -->
    <!-- Stackable Component Containers-->
    <section class="component primary-component sticky">
      <app-left-nav></app-left-nav>
    </section>
  </aside>
</div>
